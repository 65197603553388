import { LogoCloud, toImageProps } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

export type TLogoCloudConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'logo-cloud' }
>;

const LogoCloudConnected: React.FC<TLogoCloudConnectedProps> = props => {
  const { items } = props;

  return (
    <LogoCloud items={items.map(item => ({ logo: toImageProps(item.logo) }))} />
  );
};

export default LogoCloudConnected;
